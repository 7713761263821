body {
  background-color: #121212;
  color: white;
  font-family: 'Arial', sans-serif;
}

.header {
  height: 80px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0a2644;
  border-bottom: 1px solid #000000;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

input[type="text"] {
  width: 60%;
  padding: 10px;
  margin: 10px 0;
  background-color: #333;
  border: none;
  color: white;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}


iframe {
  width: 100%;
  height: 100px;
  background-color: #333;
  color: white;
  margin-bottom: 10px;
  cursor: pointer;
}

.url-frame {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px;
  background-color: #333;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  transition: transform 0.2s;
  width: 100%;
  box-sizing: border-box; 
}

.url-text {
  flex: 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  word-break: break-all; /* Add this line */
}


.copy-success {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 1;
  width: auto;
  max-width: 90%;
  box-sizing: border-box;
}

.iframe-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 363px;
}

.scrolling-frame {
  /* Your existing styles */
  height: 400px; /* Fixed height */
  overflow-y: scroll; /* Enable vertical scrolling */
}

/* Hide the scrollbar */
.scrolling-frame::-webkit-scrollbar {
  width: 0; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.scrolling-frame {
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.error-message {
  margin-top: 20px;
  color: #ff0000;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border: 1px solid #ff0000;
  border-radius: 8px;
  background-color: #ffe5e5;
}
.success-message {
  margin-top: 20px;
  color: #1ea330;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border: 1px solid #00ff40;
  border-radius: 8px;
  background-color: #ffe5e5;
}

.open-button {
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.open-button:hover {
  background-color: #218838;
}

.converter-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.converter-dropdown {
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.converter-dropdown:focus {
  outline: none;
}

.converter-dropdown option {
  background-color: white;
  color: black;
}

.converter-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Adjust as needed */
}

.converter-buttons button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.converter-buttons button.active {
  background-color: #0056b3;
}

.header-frame {
  background-color: #223f99;
  height: 40px;
  border-radius: 10px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  font-family: system-ui, sans-serif;
  font-size: 13px;
}


.recent-converts-frame {
  position: fixed;
  top: 0;
  right: 0;
  width: 25%; /* Adjust initial width as needed */
  max-width: 200px; /* Maximum width */
  min-width: 150px; /* Minimum width */
  height: 100%; /* Full height */
  background-color: #1b2224; /* Light gray background */
  border-left: 5px solid #464545; /* Border on the left side */
  padding: 10px; /* Add some padding */
  box-sizing: border-box; /* Ensure padding is included in width */
  z-index: 2; /* Ensure recent converts frame is above other content */
  border-radius: 10px;
}

/* Style for the title of recent converts */
.recent-converts-title {
  font-size: 16px; /* Adjust font size */
  font-weight: bold;
  margin-bottom: 10px; /* Add some space below the title */
}

/* Style for the scrolling list of recent converts */
.recent-converts-scroll {
  max-height: calc(100% - 40px); /* Adjust max height, accounting for title and padding */
  overflow-y: auto; /* Enable vertical scrolling */
}


.recent-convert {
  cursor: pointer;
  padding: 10px 0; /* Increase padding for more space */
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  background-color: #3f4346;
  border-radius: 10px;
  margin-bottom: 10px; /* Add margin between each recent convert */
}
.recent-converts-scroll {
  max-height: calc(100% - 40px); /* Adjust max height, accounting for title and padding */
  overflow-y: auto; /* Enable vertical scrolling */
}

.close-button-container {
  min-width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsl(0, 100%, 50%);
  color: #fff;
  font-weight: bold;
  font-family: system-ui, sans-serif;
  cursor: pointer;
  z-index: 1;
  border-radius: 10%;
  margin-left: auto;
  margin-right: 10px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.open-button {
  position: absolute; /* Position the button relative to its closest positioned ancestor */
  top: 10px; /* Adjust top position as needed */
  left: -60px; /* Move the button off-screen initially */
  z-index: 1; /* Ensure the button appears above other content */
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: left 0.3s ease; /* Add transition for smooth animation */
}

.recent-converts-frame.hidden {
  transform: translateX(100%); /* Move the sidebar off-screen */
}

.popup-content {
  background-color: rgb(42, 51, 71);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.close-btn {
  position: absolute; /* Position relative to the popup frame */
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-size: 15px;
  background-color: gray;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #634b4b;
  background-color: rgb(25, 38, 44);
  font-weight: bold;
  font-family: system-ui, sans-serif;
  color: white;
}

.set-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button-container:hover {
  background-color: red; /* Change the background color on hover if needed */
}

.recent-convert-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  pointer-events: none; /* Disable pointer events on hover */
}

.recent-convert:hover .recent-convert-text {
  background-color: transparent; /* Remove the background color change on hover */
}

.dropdown-container {
  margin-bottom: 20px;
}

.dropdown-label {
  margin-right: 10px;
  font-weight: bold;
}

.dropdown-select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.selected-game {
  margin-top: 10px;
  font-style: italic;
  color: #666;
}
a {
  color: blue; /* Set the link color to blue */
  text-decoration: underline; /* Underline the link */
}

a:hover {
  color: red; /* Set a different color when the link is hovered over */
  text-decoration: underline; /* Maintain underline on hover */
}



/* Popup overlay */
.popup-overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup content styling */
.popup-content1 {
  background-color: #2a2e3b; /* Dark gray background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 80%; /* Responsive width */
  max-width: 400px; /* Max width for large screens */
  min-width: 250px; /* Min width for small screens */
  box-sizing: border-box;
  text-align: center;
}

/* Update message text */
.popup-content1 p {
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #f2f2f2; /* Light text color */
  word-wrap: break-word;
  white-space: normal; /* Allow wrapping text */
}

/* Close button styling */
.close-btn1 {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-size: 20px;
  background-color: red;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
}

.close-btn1:hover {
  background-color: darkred;
}

/* Close button at the bottom of the popup */
.close-btn-container1 {
  display: flex;
  justify-content: center;
}

.close-btn-container1 button {
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.close-btn-container1 button:hover {
  background-color: darkred;
}

/* Mobile responsiveness */
@media screen and (max-width: 600px) {
  .popup-content1 {
    width: 90%; /* Adjust the width for mobile */
  }

  .popup-content1 p {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .close-btn-container1 button {
    padding: 8px 16px;
    font-size: 0.9rem; /* Slightly smaller button text */
  }
}

/* Footer Styles */
.footer {
  background-color: #181818;
  color: #b3b3b3;
  padding: 20px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
}

.footerContent {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.footerLink {
  color: #b3b3b3;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.footerLink:hover {
  color: #ffffff;
}

.separator {
  color: #b3b3b3;
  margin: 0 10px;
}