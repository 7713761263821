/* Scoped styles for S2SAuthComponent */
.container {
  padding: 20px;
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto; /* Ensures the container is centered horizontally */
}

.card,
.table {
  width: 100%;
  max-width: 600px;
  text-align: center; /* Center content by default for the table */
}

.card {
  border-radius: 8px;
  padding: 20px;
  background-color: #444;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  text-align: left; /* Align content to the left for single transaction */
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #333;
  border-radius: 8px;
}

.tableRow {
  border-bottom: 1px solid #555;
}

.tableCell {
  border: 1px solid #555;
  padding: 12px 18px;
  text-align: left;
  font-size: 14px;
  color: #f0f0f0;
  word-wrap: break-word;
}

.error {
  color: #e74c3c;
  font-weight: bold;
}

.loading {
  font-size: 18px;
  text-align: center;
}

.title {
  font-size: 24px;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 10px;
    align-items: center;
  }

  .card {
    padding: 15px;
  }

  .tableCell {
    font-size: 12px;
    padding: 8px 12px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 5px;
  }

  .card {
    width: 90%;
    padding: 10px;
  }

  .tableCell {
    font-size: 12px;
    padding: 6px 10px;
  }
}
